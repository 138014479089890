import { AppActions } from './app/app.actions';
import { FormsActions } from './forms/forms.actions';
import { MapActions } from './map/map.actions';
import { OfflineMapsActions } from './offlineMaps/offlineMaps.actions';
import { RecordsActions } from './records/records.actions';

export enum TypeKeys {
  NULL = 'NULL',

  AUTH_START = 'AUTH_START',
  AUTH_RESTART = 'AUTH_RESTART',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_FAIL = 'AUTH_FAIL',
  AUTH_VERIFY_START = 'AUTH_VERIFY_START',
  AUTH_VERIFY_SUCCESS = 'AUTH_VERIFY_SUCCESS',
  AUTH_VERIFY_FAIL = 'AUTH_VERIFY_FAIL',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  AUTH_UPDATE_USER = 'AUTH_USER_UPDATE',
  AUTH_UPDATE_USER_SUCCESS = 'AUTH_USER_UPDATE_SUCCESS',
  AUTH_UPDATE_USER_FAILURE = 'AUTH_USER_UPDATE_FAILURE',
  AUTH_SET_SIGNUP_PROJECT_ID = 'AUTH_SET_SIGNUP_PROJECT_ID',

  APP_INIT = 'APP_INIT',
  APP_DISMISS_TUTORIAL = 'APP_DISMISS_TUTORIAL',
  APP_LOAD = 'APP_LOAD',
  APP_LOAD_SUCCESS = 'APP_LOAD_SUCCESS',
  APP_LOAD_FAILURE = 'APP_LOAD_FAILURE',
  APP_INSTALL = 'APP_INSTALL',
  APP_INSTALL_SUCCESS = 'APP_INSTALL_SUCCESS',
  APP_INSTALL_FAILURE = 'APP_INSTALL_FAILURE',
  APP_UPDATE_AVAILABLE = 'APP_UPDATE_AVAILABLE',
  APP_UPDATE_APP = 'APP_UPDATE_APP',
  APP_UPDATE_APP_SUCCESS = 'APP_UPDATE_APP_SUCCESS',
  APP_ACCESS_DENIED = 'APP_ACCESS_DENIED',
  APP_TRIAL_EXPIRED = 'APP_TRIAL_EXPIRED',
  APP_AVAILABLE = 'APP_AVAILABLE',
  APP_UNINSTALL = 'APP_UNINSTALL',
  APP_WELCOME_SEEN = 'APP_WELCOME_SEEN',
  APPS_RESET = 'APPS_RESET',
  APP_DOWNLOAD_MEDIA = 'APP_DOWNLOAD_MEDIA',
  APP_DOWNLOAD_MEDIA_SUCCESS = 'APP_DOWNLOAD_MEDIA_SUCCESS',
  APP_DOWNLOAD_MEDIA_ERROR = 'APP_DOWNLOAD_MEDIA_ERROR',
  APP_DOWNLOAD_ATTACHMENTS = 'APP_DOWNLOAD_ATTACHMENTS',
  APP_DOWNLOAD_ATTACHMENTS_SUCCESS = 'APP_DOWNLOAD_ATTACHMENTS_SUCCESS',
  APP_DOWNLOAD_ATTACHMENTS_ERROR = 'APP_DOWNLOAD_ATTACHMENTS_ERROR',
  APP_SWITCH_ENVIRONMENT = 'APP_SWITCH_ENVIRONMENT',
  APP_SYNC = 'APP_SYNC',
  APP_SYNC_SUCCESS = 'APP_SYNC_SUCCESS',
  APP_SYNC_ERROR = 'APP_SYNC_ERROR',
  APP_OFFLINE_MIGRATION_COMPLETE = 'APP_OFFLINE_MIGRATION_COMPLETE',
  APP_UPDATE_SETTINGS = 'APP_UPDATE_SETTINGS',
  APP_UPDATE_NETWORK = 'APP_UPDATE_NETWORK',
  APP_SYNC_STATE_UPDATE = 'APP_SYNC_STATE_UPDATE',

  FORM_INITIALIZE = 'FORM_INITIALIZE',
  FORM_HYDRATE = 'FORM_HYDRATE',
  FORM_UPDATE_RECORD_DATA = 'FORM_UPDATE_RECORD_DATA',
  FORM_SET_SECTION_COMPLETE = 'FORM_SET_SECTION_COMPLETE',
  FORM_ADD_RECORD_ASSOCIATE = 'FORM_ADD_RECORD_ASSOCIATE',
  FORM_UPDATE_RECORD_ASSOCIATE = 'FORM_UPDATE_RECORD_ASSOCIATE',
  FORM_REMOVE_RECORD_ASSOCIATE = 'FORM_REMOVE_RECORD_ASSOCIATE',
  FORM_ADD_RECORD_ASSOCIATED_TO = 'FORM_ADD_RECORD_ASSOCIATED_TO',
  FORM_REMOVE_RECORD_ASSOCIATED_TO = 'FORM_REMOVE_RECORD_ASSOCIATED_TO',
  FORM_RESTORE_RECORD_ASSOCIATE = 'FORM_RESTORE_RECORD_ASSOCIATE',
  FORM_RESTORE_RECORD_ASSOCIATED_TO = 'FORM_RESTORE_RECORD_ASSOCIATED_TO',
  FORM_CLONE_RECORD_ASSOCIATE = 'FORM_CLONE_RECORD_ASSOCIATE',
  FORM_UPDATE_RECORD_GEOMETRY = 'FORM_UPDATE_RECORD_GEOMETRY',
  FORM_ADD_RECORD_ATTACHMENT = 'FORM_ADD_RECORD_ATTACHMENT',
  FORM_REPLACE_RECORD_ATTACHMENT = 'FORM_REPLACE_RECORD_ATTACHMENT',
  FORM_REMOVE_RECORD_ATTACHMENT = 'FORM_REMOVE_RECORD_ATTACHMENT',
  FORM_RESET = 'FORM_RESET',
  FORM_NEXT = 'FORM_NEXT',
  FORM_PREV = 'FORM_PREV',
  FORM_ADD_CHILD_RECORD = 'FORM_ADD_CHILD_RECORD',
  FORM_DISMISS = 'FORM_DISMISS',
  FORM_COMPLETE = 'FORM_COMPLETE',
  FORM_FINISHED = 'FORM_FINISHED',
  FORM_DISMISS_ALL = 'FORM_DISMISS_ALL',
  FORM_CLEAR_DIRTY = 'FORM_CLEAR_DIRTY',

  FORMS_SUPPORT_UPDATE_GEOMETRY_TYPE = 'FORMS_SUPPORT_UPDATE_GEOMETRY_TYPE',
  FORMS_SUPPORT_UPDATE_GEOMETRY_SETTINGS = 'FORMS_SUPPORT_UPDATE_GEOMETRY_SETTINGS',
  FORMS_SUPPORT_RESET_GEOMETRY_SETTINGS = 'FORMS_SUPPORT_RESET_GEOMETRY_SETTINGS',
  FORMS_SUPPORT_UPDATE_ATTRIBUTE = 'FORMS_SUPPORT_UPDATE_ATTRIBUTE',
  FORMS_SUPPORT_SET_RESTORE_MEDIA_ATTRIBUTE = 'FORMS_SUPPORT_SET_RESTORE_MEDIA_ATTRIBUTE',
  FORMS_SUPPORT_SET_RESTORE_MEDIA_URL = 'FORMS_SUPPORT_SET_RESTORE_MEDIA_URL',
  FORMS_SUPPORT_CLEAR_RESTORE_MEDIA = 'FORMS_SUPPORT_CLEAR_RESTORE_MEDIA',

  RECORDS_UPDATE_FILTER = 'RECORDS_UPDATE_FILTER',
  RECORDS_CLEAR_FILTER = 'RECORDS_CLEAR_FILTER',
  RECORDS_ADD_CUSTOM_FILTER = 'RECORDS_ADD_CUSTOM_FILTER',
  RECORDS_UPDATE_CUSTOM_FILTER = 'RECORDS_UPDATE_CUSTOM_FILTER',
  RECORDS_DELETE_CUSTOM_FILTER = 'RECORDS_DELETE_CUSTOM_FILTER',
  RECORDS_LOAD_RECORD = 'RECORDS_LOAD_RECORD',
  RECORDS_LOAD_RECORD_SUCCESS = 'RECORDS_LOAD_RECORD_SUCCESS',
  RECORDS_LOAD_RECORD_FAILURE = 'RECORDS_LOAD_RECORD_FAILURE',
  RECORDS_CLEAR = 'RECORDS_CLEAR',
  RECORDS_LOAD_MORE = 'RECORDS_LOAD_MORE',
  RECORDS_LOAD_MORE_SUCCESS = 'RECORDS_LOAD_MORE_SUCCESS',
  RECORDS_LOAD_MORE_FAILURE = 'RECORDS_LOAD_MORE_FAILURE',
  RECORDS_LOAD_MAP = 'RECORDS_LOAD_MAP',
  RECORDS_LOAD_MAP_SUCCESS = 'RECORDS_LOAD_MAP_SUCCESS',
  RECORDS_LOAD_MAP_FAILURE = 'RECORDS_LOAD_MAP_FAILURE',
  RECORDS_SAVE_PENDING = 'RECORDS_SAVE_PENDING',
  RECORDS_SUBMIT = 'RECORDS_SUBMIT',
  RECORDS_SUBMIT_SUCCESS = 'RECORDS_SUBMIT_SUCCESS',
  RECORDS_SUBMIT_FAILURE = 'RECORDS_SUBMIT_FAILURE',
  RECORDS_DISCARD_PENDING = 'RECORDS_DISCARD_PENDING',

  MAP_UPDATE_STATE = 'MAP_UPDATE_STATE',
  MAP_CLEAR_STATE = 'MAP_CLEAR_STATE',

  OFFLINE_MAPS_CREATE = 'OFFLINE_MAPS_CREATE',
  OFFLINE_MAPS_DELETE = 'OFFLINE_MAPS_DELETE',
  OFFLINE_MAPS_REORDER = 'OFFLINE_MAPS_REORDER',
  OFFLINE_MAPS_DOWNLOAD_COMPLETE = 'OFFLINE_MAPS_DOWNLOAD_COMPLETE',
  OFFLINE_MAPS_DOWNLOAD_ERROR = 'OFFLINE_MAPS_DOWNLOAD_ERROR',
  OFFLINE_MAPS_DOWNLOAD_RETRY = 'OFFLINE_MAPS_DOWNLOAD_RETRY',
  OFFLINE_MAPS_TOGGLE_AMBIENT = 'OFFLINE_MAPS_TOGGLE_AMBIENT',
  OFFLINE_MAPS_TUTORIAL_SHOWN = 'OFFLINE_MAPS_TUTORIAL_SHOWN'
};

export interface NullAction {
  type: TypeKeys.NULL
};

export type Actions =
  | RecordsActions
  | AppActions
  | FormsActions
  | MapActions
  | OfflineMapsActions
  | NullAction;
